<template>
  <div v-if="productData">
    <div class="banner">
      <img :src="productData.detailImg" alt="" />
    </div>
    <div class="detail">
      <p class="name">{{ productData.mainName }}</p>
    </div>
    <div class="line"></div>
    <div class="typeList">
      <p
        v-for="(item, index) in plansData"
        :key="index"
        :class="activeType == index ? 'active' : 'no-active'"
        @click="changeType(index, item)"
      >
        {{ item.name }}
      </p>
    </div>

    <div class="yearList">
      <div
        v-for="(item, index) in schemeList"
        :key="item.id"
        :class="yearListNo == index ? 'itemChecked' : 'item'"
        @click="changeYearList(index, item)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="ensureDetail">
      <div class="inviolable-rights-msg">
        <div class="item" v-for="(item, index) in dutyList" :key="index">
          <p class="name">{{ item.name }}<span @click="detail(item)">详情</span></p>
          <p class="price">{{ item.price }}</p>
        </div>
      </div>
    </div>

    <div class="line"></div>
    <div class="productImg">
      <van-tabs v-model="tabActive" scrollspy sticky>
        <van-tab
          v-for="(item, index) in tabList"
          :title="item.title"
          :key="index"
        >
          <div class="msgImg" v-html="item.img"></div>
        </van-tab>
      </van-tabs>
    </div>

    <div class="footer">
      <div class="clauseBox">
        <div class="clause">
          <van-checkbox
            v-model="isRead"
            shape="square"
            icon-size="14px"
            checked-color="#FF6C40"
          >
          </van-checkbox>
          <p>我已阅读</p>
          <div @click="customerNotification('xmInsuranceClause')">保险条款</div>
          <div @click="customerNotification('xmInsuranceInformation')">
            投保须知
          </div>
        </div>
      </div>
      <div class="infoBox">
        <!-- <div class="information" @click="material">
          <img src="../assets/information-icon.png" alt="" />
          <p class="name">资料</p>
          <p class="right-line"></p>
        </div> -->
        <div class="price">
          <p style="margin-left: 10px">价格：¥</p>
          <p class="num" style="margin-left: 3px">
            {{ price == "--" ? "--" : price / 100 }}
          </p>
          <p>元/起</p>
        </div>
        <div class="submit" @click="submit">立即投保</div>
      </div>
    </div>
    <!-- 回溯须知 -->
    <div class="firstBottomPopup">
      <van-popup
        v-model="recallPopup"
        :close-on-click-overlay="false"
        position="bottom"
      >
        <div class="notify">
          <div class="logoList">
            <img src="../assets/xinmei-logo.png" alt="" />
            <!-- <img :src="productData.companyLogo" alt="" /> -->
            <p class="border"></p>
            <img src="../assets/lejiandai-logo.png" alt="" />
          </div>
          <p class="company">来自：信美人寿相互保险社</p>
          <p class="company">代销：乐荐保保险代理有限公司</p>
          <p class="line"></p>
          <div class="tips">
            <p class="tit">温馨提示，您即将进入投保流程：</p>
            <p class="msg">
              请您仔细阅读保险条款、投保须知等内容，为保障您的权益，您在销售页面的操作将会被记录并加密存储
            </p>
            <p class="notifyPdf">
              查看<span @click="customerNotification('native')"
                >《客户告知书》</span
              ><span @click="customerNotification('privacyPolicy')"
                >《隐私条款》</span
              >
            </p>
          </div>
          <div class="btns">
            <p>残忍离开</p>
            <p @click="okBtn">我知道了</p>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 健康告知、免责条款 -->
    <div class="bottomPopup">
      <van-popup
        v-model="informPopup"
        :close-on-click-overlay="false"
        position="bottom"
      >
        <div class="inform" v-if="isInform">
          <div class="title">
            <p>健康告知</p>
            <img
              src="../assets/close-icon.png"
              @click="informPopup = false"
              alt=""
            />
          </div>
          <div class="detail">
            <p>被保险人曾经或目前是否有以下情况：</p>
            <p>
              被确诊为恶性肿瘤、肝硬化、丙型肝炎、酒精性肝脏疾病、肝性脑病、肝肾综合征，有或被怀疑有肝区疼痛、腹水、出血倾向、食管/胃底静脉曲张、呕血？是否有性质不明或待定的肝脏检查结果？
            </p>
          </div>
          <div class="btns">
            <p @click="healthShow = true">部分为是</p>
            <p @click="isInform = false">全部为否</p>
          </div>
        </div>
        <div class="inform" v-else>
          <div class="title">
            <p>客户告知书</p>
            <img
              src="../assets/close-icon.png"
              @click="
                informPopup = false;
                isInform = true;
              "
              alt=""
            />
          </div>
          <div class="detail" style="height: 280px">
            <p>尊敬的客户：</p>
            <p>
              本公司是根据保险人的委托，
              在保险人授权的范围内代为办理保险业务的保险专业代理机构。
              您在乐荐代所购买的保险产品由乐荐保保险代理有限公司代理销售，
              为了保护您的合法权益，
              按照《保险专业代理机构监管规定》及《互联网保险业务监管暂行办法》的要求，
              本公司应履行客户告知义务， 现将有关事项告知如下， 请仔细阅读。
            </p>
            <p>一、</p>
            <p>公司基本情况</p>
            <p>(一)</p>
            <p>名称：乐荐保保险代理有限公司</p>
            <p>(二)</p>
            <p>地址：四川省成都市锦江区东风路27号3幢1楼02号(自编号1号楼)</p>
            <p>(三)</p>
            <p>
              许可证名称及编号：经营保险代理业务许可证， 编号：210177000000800
            </p>
            <p>(四)</p>
            <p>
              业务范围：代理销售保险产品；代理收取保险费；代理相关保险业务的损失勘查和理赔；中国保监会批准的其他业务。
            </p>
            <p>(五)</p>
            <p>经营区域：全国（港、 澳、 台除外）</p>
            <p>(六)</p>
            <p>联系方式：028-86768726</p>
            <p>二、</p>
            <p>
              请仔细阅读保险条款， 重点关注保险责任、
              责任免除、被保险人权利义务、 免赔额或免赔率的计算、
              退保及其他费用扣除、 健康保险产品等待期等内容，
              并可要求本公司客服人员对上述内容进行详细讲解。
            </p>
            <p>三、</p>
            <p>
              请向客服人员了解《中华人民共和国保险法》等法律法规对于索赔时效、
              保险公司理赔时限、 合同中止与失效、 未成年人投保限额等的相关规定，
              以及不履行如实告知义务、 故意制造保险事故或夸大事故损失、
              申报年龄不真实等情形导致的法律后果。
            </p>
            <p>四、</p>
            <p>
              本公司承诺将通过有效的技术手段和管理措施对投保人（被保险人或受益人）的个人信息、
              投保交易信息等非公开信息进行保密， 严格限制保密信息的接触人，
              妥善保管保密信息；并在与保险公司的数据交换过程中采用了加密与验证保证交易安全。
            </p>
            <p>五、</p>
            <p>
              如果你发现本公司客服人员存在误导行为及其他损害你合法权益的行为，
              请注意保留书面证据或其他证据， 可向本公司投诉，
              投诉电话：028-86768726
            </p>
          </div>
          <div class="next">
            <p @click="next">阅读完毕，继续投保</p>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 健康提示 -->
    <div class="centerPopup">
      <van-popup v-model="healthShow" :close-on-click-overlay="false">
        <div class="health">
          <p class="title">健康提示</p>
          <p class="tip">很遗憾，您的条件不符合参保条件</p>
          <p class="btn" @click="healthShow = false">我知道了</p>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { productDetail, xmReckon } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      activeType: 0,
      tabList: [
        {
          title: "产品介绍",
        },
        {
          title: "理赔流程",
        },
        {
          title: "理赔案例",
        },
        {
          title: "常见问题",
        },
      ],
      tabActive: 0,
      productData: null,
      plansId: "",
      plansData: [
        {
          name: "肝脏恶性肿瘤保障计划",
          id: 1,
        },
        {
          name: "失代偿期肝硬化保障计划",
          id: 2,
        },
        {
          name: "综合保障计划",
          id: 3,
        },
      ],
      recallPopup: true,
      isFirstEnter: false,
      informPopup: false,
      isInform: true,
      healthShow: false,

      yearListNo: 0, // 年限序号
      isRead: false, //是否 勾选阅读文件

      schemeList: [], //方案列表
      schemeId: "", //选中方案id
      dutyList: [], //保障责任列表
      price: 9320, //价格
    };
  },
  async created() {
    document.title = "信美相互肝护保疾病保险";
    localStorage.removeItem("xmIssueParams");
    //判断是否需要重新获取回溯id
    if (!sessionStorage.getItem("know")) {
      this.recallPopup = true;
    } else {
      this.recallPopup = false;
    }

    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    await this.productDetail();
    this.$toast.clear();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "rightsAndInterests") {
      to.meta.isBack = true;
    }
    next();
  },
  async activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.informPopup = false;
      if (!sessionStorage.getItem("know")) {
        this.recallPopup = true;
      } else {
        this.recallPopup = false;
      }
      this.isFirstEnter = false;
    }

    // await this.productDetail();

    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  methods: {
    //查看责任详情
    detail(item) {
      console.log(item)
      let msg;
      if(item.name == '肝脏恶性肿瘤-重度'){
        msg = '被保险人于等待期后经我们认可的医院初次确诊患有保险合同所定义的肝脏恶性肿瘤——重度，我们按保险合同的肝脏恶性肿瘤——重度基本保险金额给付肝脏恶性肿瘤——重度保险金，本项保险责任终止。'
      }else if(item.name == '肝脏恶性肿瘤-重度住院津贴'){
        msg = '被保险人于等待期后经我们认可的医院初次确诊患有保险合同所定义的肝脏恶性肿瘤——重度，并因患有肝脏恶性肿瘤——重度在医院接受住院治疗的，我们按保险合同的肝脏恶性肿瘤——重度每日住院津贴金额与被保险人实际住院天数的乘积给付肝脏恶性肿瘤——重度住院津贴保险金，即：肝脏恶性肿瘤——重度住院津贴保险金=肝脏恶性肿瘤——重度每日住院津贴金额×实际住院天数；我们在每一保险期间内累计给付肝脏恶性肿瘤——重度住院津贴保险金的天数以90日为限，累计给付达到90日的，本项保险责任终止。被保险人接受住院治疗且在保险期间届满时治疗仍未结束的，对于被保险人该次住院治疗延续至保险期间届满日次日起30日内的住院，我们仍在上述90日的给付天数范围内继续承担给付肝脏恶性肿瘤——重度住院津贴保险金的责任。对于被保险人该次住院治疗延续至保险期间届满日次日起30日后的住院，我们不再承担给付肝脏恶性肿瘤——重度住院津贴保险金的责任。当被保险人所选择的一项或多项保险责任均终止后，保险合同终止。'
      }else if(item.name == '失代偿期肝硬化'){
        msg = '被保险人于等待期后经我们认可的医院初次确诊患有保险合同所定义的失代偿期肝硬化，我们按保险合同的失代偿期肝硬化基本保险金额给付失代偿期肝硬化保险金，本项保险责任终止。'
      }
      this.$dialog
        .alert({
          message: msg,
          messageAlign:'left',
          confirmButtonText:"关闭",
        })
        .then(() => {
          // on close
        });
    },
    async xmReckon() {
      let params = {
        age: 18,
        planId: this.plansId,
        schemeId: this.schemeId,
      };
      this.$toast.loading({
        message: "价格计算中...",
        forbidClick: true,
        duration: 0,
      });
      await xmReckon(params).then((res) => {
        this.$toast.clear();
        let price = 0;
        res.result.map((item) => {
          price += parseFloat(item.reckonPrice);
        });
        this.price = price;
      });
    },
    //客户告知书、隐私条款
    customerNotification(type) {
      this.$router.push({
        path: "/pdf",
        query: {
          type: type,
        },
      });
    },
    //跳转产品资料
    // material() {
    //   this.$router.push({
    //     path: "/material",
    //     query: {
    //       id: this.$route.query.id,
    //     },
    //   });
    // },
    //阅读并投保
    next() {
      this.isInform = true;
      this.informPopup = false;
      this.$router.push({
        path: "/insurePackage",
        query: {
          id: this.$route.query.id,
          planId: this.plansId,
          schemeId: this.schemeId,
        },
      });
    },
    //立即投保
    submit() {
      if (this.isRead) {
        this.informPopup = true;
      } else {
        this.$toast("请先阅读相关资料");
      }
    },
    //权益-查看详情
    // rightsAndInterests() {
    //   this.$router.push({
    //     path: "/rightsAndInterests",
    //     query: {
    //       id: this.plansId,
    //     },
    //   });
    // },
    //我知道了
    okBtn() {
      this.recallPopup = false;
      sessionStorage.setItem("know", true); //存储用户是否知晓该提示
    },
    //产品详情
    async productDetail() {
      let params = {
        productId: this.$route.query.id,
        // productId: "1450714352756387842",
      };
      await productDetail(params).then((res) => {
        this.productData = res.result;
        this.plansId = this.plansData[0].id;
        // 方案
        this.schemeList = [
          {
            name: "93.2/年",
            id: 1,
          },
          {
            name: "186.4/年",
            id: 2,
          },
          {
            name: "233/年",
            id: 3,
          },
        ];
        this.schemeId = 1;
        //责任
        this.dutyList = [
          {
            name: "肝脏恶性肿瘤-重度",
            price: "10万",
          },
          {
            name: "肝脏恶性肿瘤-重度住院津贴",
            price: "200元/天",
          },
        ];
        //产品详情图片
        this.tabList[0]["img"] = this.productData.detail;
        this.tabList[1]["img"] = this.productData.notice;
        this.tabList[2]["img"] = this.productData.claimsGuidelines;
        this.tabList[3]["img"] = this.productData.classicCases;
      });
    },
    //切换计划type
    async changeType(index, item) {
      this.activeType = index;
      this.plansId = item.id;

      if (this.schemeId == 4) {
        this.schemeId = 1;
        this.yearListNo = 0;
      }
      if (index == 0) {
        this.schemeList = [
          {
            name: "93.2/年",
            id: 1,
          },
          {
            name: "186.4/年",
            id: 2,
          },
          {
            name: "233/年",
            id: 3,
          },
        ];
        if (this.schemeId == 1) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "10万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "200元/天",
            },
          ];
        } else if (this.schemeId == 2) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "20万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "400元/天",
            },
          ];
        } else if (this.schemeId == 3) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "25万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "500元/天",
            },
          ];
        }
      } else if (index == 1) {
        this.schemeList = [
          {
            name: "249.5/年",
            id: 1,
          },
          {
            name: "499/年",
            id: 2,
          },
          {
            name: "748.5/年",
            id: 3,
          },
        ];
        if (this.schemeId == 1) {
          this.dutyList = [
            {
              name: "失代偿期肝硬化",
              price: "5万",
            },
          ];
        } else if (this.schemeId == 2) {
          this.dutyList = [
            {
              name: "失代偿期肝硬化",
              price: "10万",
            },
          ];
        } else if (this.schemeId == 3) {
          this.dutyList = [
            {
              name: "失代偿期肝硬化",
              price: "15万",
            },
          ];
        }
      } else if (index == 2) {
        this.schemeList = [
          {
            name: "296.1/年",
            id: 1,
          },
          {
            name: "592.2/年",
            id: 2,
          },
          {
            name: "435.9/年",
            id: 3,
          },
          {
            name: "685.4/年",
            id: 4,
          },
        ];

        if (this.schemeId == 1) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "5万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "100元/天",
            },
            {
              name: "失代偿期肝硬化",
              price: "5万",
            },
          ];
        } else if (this.schemeId == 2) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "10万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "20元/天",
            },
            {
              name: "失代偿期肝硬化",
              price: "10万",
            },
          ];
        } else if (this.schemeId == 3) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "20万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "400元/天",
            },
            {
              name: "失代偿期肝硬化",
              price: "5万",
            },
          ];
        } else if (this.schemeId == 4) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "20万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "400元/天",
            },
            {
              name: "失代偿期肝硬化",
              price: "10万",
            },
          ];
        }
      }
      await this.xmReckon();
      // this.productBenefitDetail();
    },

    // 切换方案
    async changeYearList(index, item) {
      this.yearListNo = index;
      this.schemeId = item.id;
      if (index == 0) {
        if (this.plansId == 1) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "10万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "200元/天",
            },
          ];
        } else if (this.plansId == 2) {
          this.dutyList = [
            {
              name: "失代偿期肝硬化",
              price: "5万",
            },
          ];
        } else if (this.plansId == 3) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "5万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "100元/天",
            },
            {
              name: "失代偿期肝硬化",
              price: "5万",
            },
          ];
        }
      } else if (index == 1) {
        if (this.plansId == 1) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "20万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "400元/天",
            },
          ];
        } else if (this.plansId == 2) {
          this.dutyList = [
            {
              name: "失代偿期肝硬化",
              price: "10万",
            },
          ];
        } else if (this.plansId == 3) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "10万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "200元/天",
            },
            {
              name: "失代偿期肝硬化",
              price: "10万",
            },
          ];
        }
      } else if (index == 2) {
        if (this.plansId == 1) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "25万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "500元/天",
            },
          ];
        } else if (this.plansId == 2) {
          this.dutyList = [
            {
              name: "失代偿期肝硬化",
              price: "15万",
            },
          ];
        } else if (this.plansId == 3) {
          this.dutyList = [
            {
              name: "肝脏恶性肿瘤-重度",
              price: "20万",
            },
            {
              name: "肝脏恶性肿瘤-重度住院津贴",
              price: "400元/天",
            },
            {
              name: "失代偿期肝硬化",
              price: "5万",
            },
          ];
        }
      } else if (index == 3) {
        this.dutyList = [
          {
            name: "肝脏恶性肿瘤-重度",
            price: "20万",
          },
          {
            name: "肝脏恶性肿瘤-重度住院津贴",
            price: "400元/天",
          },
          {
            name: "失代偿期肝硬化",
            price: "10万",
          },
        ];
      }
      await this.xmReckon();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  background: #f2f2f2;
  img {
    width: 100%;
  }
}
.detail {
  padding: 0 10px;
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    margin-top: 20px;
  }
  .introduction {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    text-align: justify;
    margin-top: 5px;
  }
  .from {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 12px;
  }
  .distribution-company {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 4px;
  }
}
.line {
  width: 100%;
  height: 10px;
  background: #f7f7f7;
  margin-top: 20px;
}
.typeList {
  // display: flex;
  // align-items: center;
  margin-top: 20px;
  padding: 0 10px;
  p {
    // width: 90px;
    padding: 10px 24px;
    margin-bottom: 10px;
    display: inline-block;
    // height: 40px;
    border-radius: 24px;
    margin-right: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
  p:last-child {
    margin-right: 0;
  }
  .active {
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    color: #fff;
    border: 1px solid $primary-color;
  }
  .no-active {
    border: 1px solid $primary-color;
    color: $primary-color;
  }
}
.yearList {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 10px;
  padding: 0 10px;
  div:last-child {
    margin-right: 0 !important;
  }
  .item {
    width: 80px;
    height: 36px;
    background: #eeeeee;
    border-radius: 18px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-right: 12px;
  }
  .itemChecked {
    margin-right: 12px;
    width: 80px;
    height: 36px;
    background: #ffe8e2;
    border-radius: 18px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff6c40;
    position: relative;
  }
  .itemChecked::after {
    position: absolute;
    left: 34px;
    bottom: -5px;
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #ffe8e2;
  }
}
.commission {
  padding: 0 15px;
  margin-top: 16px;
  .msg {
    width: 100%;
    background: url("../assets/commission-bg.png") no-repeat;
    background-size: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .left {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: bold;
        color: #e06c49;
        line-height: 20px;
      }
      img {
        width: 14px;
        margin-left: 8px;
      }
    }
    .share {
      img {
        width: 54px;
      }
    }
  }
}
.ensureDetail {
  padding: 0 10px;
  .inviolable-rights-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .left {
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
    }
    .right {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
      img {
        width: 12px;
        margin-left: 3px;
      }
    }
  }
  .inviolable-rights-msg {
    background: $bg7;
    border-radius: 4px;
    padding: 0 10px 10px;
    margin-top: 8px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      p {
        font-size: 15px;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        display: flex;
        align-items: center;

        span {
          color: #333;
          font-size: 12px;
          line-height: 12px;
          padding: 3px 5px;
          border-radius: 3px;
          border: 1px solid #999;
          display: block;
          margin-left: 8px;
        }
      }
    }
  }
}
.guaranteed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 12px;
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
  }
}
.msgImg {
  width: 100%;
  // padding: 0 10px;
  /deep/ img {
    width: 100% !important;
  }
}
.productImg {
  padding-bottom: 100px;
}
.notify {
  padding: 0 24px;
  .logoList {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    margin-top: 16px;
    margin-bottom: 16px;
    img:first-child {
      width: 62px;
    }
    p {
      width: 1px;
      height: 20px;
      background: #dfdfdf;
      margin: 0 48px;
    }
    img:last-child {
      width: 53px;
    }
  }
  .company {
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin-top: 8px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #eeeeee;
  }
  .tips {
    .tit {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      margin-top: 17px;
    }
    .msg {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-top: 12px;
      text-align: justify;
    }
    .notifyPdf {
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 17px;
      margin-top: 7px;
      span {
        color: $primary-color;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    p {
      width: 140px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
      line-height: 21px;
    }
    p:first-child {
      border-radius: 26px;
      border: 1px solid #d8d8d8;
      color: #aaaaaa;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p:last-child {
      background: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 26px;
      color: #ffffff;
    }
  }
}
.inform {
  height: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  .title {
    position: relative;
    background: #fff;

    p {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      text-align: center;
    }
    img {
      position: absolute;
      top: 4px;
      right: 0;
      width: 14px;
    }
  }
  .detail {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: justify;
    padding: 0;
    // height: 90%;
    overflow-y: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 159px;
      height: 45px;
      border-radius: 23px;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
    }
    p:first-child {
      border: 1px solid $primary-color;
      color: $primary-color;
    }
    p:last-child {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: #fff;
    }
  }
  .next {
    // position: fixed;
    // bottom: 10px;
    // left: 20px;
    p {
      height: 45px;
      border-radius: 23px;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
      width: 234px;
      background: $primary-color;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}
.health {
  width: 320px;
  height: 160px;
  background: #ffffff;
  border-radius: 8px;
  p {
    text-align: center;
  }
  .title {
    font-size: 17px;
    font-weight: 500;
    color: #333;
    line-height: 24px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .tip {
    font-size: 17px;
    font-weight: 400;
    color: #999;
    line-height: 24px;
  }
  .btn {
    margin-top: 34px;
    font-size: 17px;
    font-weight: 500;
    color: #576b95;
    line-height: 24px;
    letter-spacing: 1px;
    padding: 15px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
  height: 100px;
  border-top: 1px solid #e5e5e5;
  .clauseBox {
    padding: 12px 5px;
    .clause {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      div {
        font-size: 14px;
        font-weight: 400;
        color: #569cff;
        line-height: 16px;
        padding: 0 6px;
        display: block;
        border-right: 1px solid #e5e5e5;
      }
      div:first-child {
        border-right: none;
      }
      div:last-child {
        border-right: none;
      }
    }
  }

  .infoBox {
    // margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 21px;
      img {
        width: 19px;
      }
      p {
        font-size: 10px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        margin-top: 5px;
      }
    }
    .price {
      display: flex;
      align-items: flex-end;
      p {
        font-size: 15px;
        font-weight: 500;
        color: $primary-color;
        line-height: 15px;
      }
      .num {
        font-size: 20px;
        line-height: 19px;
      }
    }

    .submit {
      width: 116px;
      height: 45px;
      background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
      border-radius: 24px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
  }
}
/deep/ .van-tab--active {
  color: $primary-color;
}
/deep/ .van-tabs__line {
  background-color: $primary-color;
  height: 4px;
  width: 16px;
  border-radius: 2px;
  bottom: 20px;
}
.bottomPopup {
  /deep/ .van-popup {
    border-radius: 20px 20px 0 0;
    max-height: 70%;
  }
}
.centerPopup {
  /deep/ .van-popup {
    border-radius: 8px;
  }
}
.firstBottomPopup {
  /deep/ .van-popup {
    padding-bottom: 20px;
    border-radius: 20px 20px 0 0;
    height: auto;
  }
}
</style>